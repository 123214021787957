import axios, {
  AxiosError,
} from 'axios';
import {
  createPinia,
} from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import {
  getAppInfo,
} from 'utilities';
import {
  createApp,
} from 'vue';
import App from '@/App.vue';
import {
  baseUrl,
} from '@/helpers';
import router from '@/router';
import '@/style/index.css';

const app = createApp(App);
const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);
app.use(pinia);

const { ROUTE_API } = getAppInfo();

app.config.errorHandler = (err, vm, info): void => {
  console.error(`Error in ${info}:`, err);
};

axios.defaults.baseURL = `${baseUrl}${ROUTE_API}`;

app.use(router);

app.mount('#app');
