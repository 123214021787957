/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Wed Mar 08 2023
 */

import {
  AxiosError,
} from 'axios';
import {
  getAppInfo,
} from 'utilities';
import Bus, {
  NOTIFICATION,
} from '@/bus';
import {
  baseServerUrl,
} from '@/configs/client.config.json';

export const {
  SERVER_PORT,
  CLIENT_PORT,
  IS_DEVELOPMENT,
  CONFIG,
  ROUTE_API,
} = getAppInfo();

const { protocol, hostname } = window.location;

export function handleAxiosError<T extends {
  message: string;
  errors?: Map<string, string> | Record<string, string | undefined>;
}>(e: AxiosError<T>, showAdditionalError = false): void {
  console.error(e);

  if (e.isAxiosError && e.response?.data) {
    let errorMessages = '';
    let message = e.response.statusText;
    if (typeof e.response.data === 'string') {
      message = e.response.data;
    } else if (e.response.data.message) {
      message = e.response.data.message;
    }

    if (e.response.data.errors && showAdditionalError) {
      const { errors } = e.response.data;
      for (const key of Object.keys(errors)) {
        errorMessages += `, ${key}: `;
        if (errors instanceof Map) {
          errorMessages += errors.get(key);
        } else {
          errorMessages += errors[key];
        }
      }
    }

    Bus.emit(NOTIFICATION.ERROR, {
      message: message + errorMessages,
      permanent: true,
    });
  }
}

export const baseUrl = baseServerUrl || (IS_DEVELOPMENT
  ? `${protocol}//${hostname}:${SERVER_PORT}`
  : window.location.origin);

export const clientBaseUrl = IS_DEVELOPMENT
  ? `${protocol}//${hostname}:${CLIENT_PORT}`
  : window.location.origin;
